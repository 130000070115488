import NavigationBar from "../components/navigation_bar";
import '../index.css'
import * as React from "react";

export default function Root() {
    return (
        <>
            <NavigationBar />
        </>
    );
}