import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: 'right',
            labels: {
                font: {
                    size: 10,
                    weight: "bold"
                }
            },
        },
        title: {
            display: true,
            text: 'Technologies',
        },
    },
};

function generateOpacityColor(technology, opacity_index){
    if(technology === "GCP")
        return 'rgba(255, 51, 51,'+opacity_index+')'
    if(technology === "Java")
        return 'rgba(255, 128, 0,'+opacity_index+')'
    if(technology === "Python")
        return 'rgba(0, 204, 0,'+opacity_index+')'
    if(technology.includes("Git"))
        return 'rgba(96, 96, 96,'+opacity_index+')'
    if(technology === "C#")
        return 'rgba(102, 0, 102,'+opacity_index+')'
    if(technology === "C++")
        return 'rgba(51, 51, 255,'+opacity_index+')'
    if(technology === "C")
        return 'rgba(204, 0, 204,'+opacity_index+')'
    if(technology === "Spring")
        return 'rgba(0, 153, 0,'+opacity_index+')'
    if(technology === "Docker")
        return 'rgba(0, 128, 255,'+opacity_index+')'
    if(technology === "Terraform")
        return 'rgba(204, 0, 204,'+opacity_index+')'
    if(technology === "ReactJS")
        return 'rgba(0, 204, 204,'+opacity_index+')'
    if(technology === "Bash")
        return 'rgba(64, 64, 64,'+opacity_index+')'

    const red = Math.floor(Math.random() * 255);
    const green = Math.floor(Math.random() * 255);
    const blue = Math.floor(Math.random() * 255);
    return 'rgba('+red+', '+green+', '+blue+','+opacity_index+')'
}

function generateXOpacityColors(data, number, opacity_index){
    let generated = []
    for(let i = 0; i < number; i++){
        generated.push(generateOpacityColor(data[i], opacity_index))
    }
    return generated
}


export function ExperienceChart(props) {
    const backgroundColors = generateXOpacityColors(props.labels, props.data.length, 0.6);
    const borderColors = generateXOpacityColors(props.labels, props.data.length, 1);

    const data = {
        labels: props.labels,
        datasets: [
            {
                label: '# of Votes',
                data: props.data,
                backgroundColor: backgroundColors,
                borderColor: borderColors,
                borderWidth: 1,
            },
        ],
    };

    let table = []

    for(let i = 0; i < backgroundColors.length; i++){
        const color = backgroundColors[i]
        table.push(<td key={Math.random()} style={{background: color, color: "white", padding: 5, fontSize: 12}}>{props.labels[i]}</td>)
    }

    return (
        <div className={"pieFrame"}>
            <Pie options={options} data={data} />
            <table>
                <tbody>
                    <tr>
                        {table}
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
