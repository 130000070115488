import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InfoIcon from '@mui/icons-material/Info';
import MailIcon from '@mui/icons-material/Mail';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import avatarImage from '../1659528629425.jpg'
import ExperienceEntry from "./experience_entry";
import testdata from "../testdata.json"

const drawerWidth = 200;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const experienceData = testdata

const transposedData = experienceData.map((x) =>
        <ExperienceEntry key={Math.random()} data={x}/>
)

export default function NavigationBar() {

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar className={"websiteTitle"} position="fixed" open={true} style={{color:"black"}}>
                <Toolbar >
                    <Typography   variant="h5" noWrap component="div">
                        Helesteanu Constantin-Adrian
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={true}
            >
                <DrawerHeader sx={{padding: 0}}>
                    <div className="avatarFrame">
                        <img className="avatarImage" src={avatarImage} alt="None" />
                    </div>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItem key={"About me"} disablePadding>
                        <ListItemButton href={"/"}>
                            <ListItemIcon>
                                    <InfoIcon />
                            </ListItemIcon>
                            <ListItemText primary={"About me"} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key={"LinkedIn"} disablePadding>
                        <ListItemButton href={"https://www.linkedin.com/in/constantin-adrian-helesteanu/"} target={"_blank"}>
                            <ListItemIcon>
                                <LinkedInIcon />
                            </ListItemIcon>
                            <ListItemText primary={"LinkedIn"} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key={"Contact"} disablePadding>
                        <ListItemButton  href={"mailto:constantinh.work@gmail.com"}>
                            <ListItemIcon>
                                <MailIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Contact"} />
                        </ListItemButton>
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem key={"Download CV"} disablePadding>
                        <ListItemButton href={"https://storage.googleapis.com/constantinhelesteanu-cv-public-access/LatestCV-HelesteanuConstantinAdrian.pdf"} target={"_blank"}>
                            <ListItemIcon>
                                <CloudDownloadIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Download CV"} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
            <Main open={true}>
                <DrawerHeader />
                {transposedData}
            </Main>
        </Box>
    );
}