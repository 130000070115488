import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {ExperienceChart} from "./experience_chart";
import { Divider } from '@mui/material';


export default function ExperienceEntry(props) {
    const descriptions = props.data.companyDescription
    const keys = Object.keys(descriptions)


    return (
        <Card className={"experienceCard"} sx={{ minWidth: 300 }}>
            <div className={"experienceFrame"}>
                <a className={"experienceLink"} href={props.data.companyWebsite} target={"_blank"} rel={"noreferrer"}>
                    <img className={"experienceCI"} src={props.data.companyLogo} alt={props.data.companyName} />
                </a>
                <CardContent sx={{minWidth: 450}}>
                    <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                        {props.data.companyTimeframe}
                    </Typography>
                    <Typography variant="h5" component="div">
                        {props.data.companyRole}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        {props.data.companyName}
                    </Typography>
                </CardContent>
                <div className={"pieFrame"}>
                    <ExperienceChart data={props.data.skillData} labels={props.data.skillLabels}/>
                </div>

            </div>
            <Divider orientation="horizontal" flexItem />
            <div className={"companyDescriptions"}>
                {
                    keys.map((key) => {
                        return (
                            <div className={"companyDescription"} key={Math.random()}>
                                <p>As {key}</p>
                                {props.data.companyDescription[key].map((values) =>{
                                    return (
                                        <li key={Math.random()}>
                                            {values}
                                        </li>
                                    )
                                })}
                            </div>
                        )
                    })
                }
            </div>
        </Card>
    );
}